import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li>
        <Link to="/">Ana Sayfa</Link>
      </li>
      <li>
        <Link to="/about">Hakkımızda</Link>
      </li>

      <li className="has-dropdown">
        <Link to="/app-details-qr">UYGULAMALARIMIZ</Link>
        <ul className="submenu">
          <li className="nav-effect">
            <Link to="/app-details-qr">QR MENÜ</Link>
          </li>

          <li className="nav-effect">
            <Link to="/app-details-panel">Restoran PANELİ</Link>
          </li>
          {/* <li>
            <Link to="/app-details-boss">BOSS PANEL</Link>
          </li> */}
        </ul>
      </li>
      <li>
        <Link to="/price">FİYATLANDIRMA</Link>
      </li>
      <li>
        <Link to="/contact">BİZE ULAŞIN</Link>
      </li>
    </ul>
  );
};

export default Menus;
