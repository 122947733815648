import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-details-info pr-65">
                <div className="about-tb-content">
                  <nav>
                    <div
                      className="nav mb-30 centered"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        <b> Felsefemiz</b>
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        <b> Sık Sorulanlar</b>
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                        IThink, herhangi bir işletmenin başarısını artırmak için
                        müşteri deneyimini öncelikli kılan bir felsefeye
                        sahiptir. Sunduğumuz QR menü çözümü, müşterilerinize en
                        iyi deneyimi sunmak için tasarlanmıştır. İşletmenizde
                        müşterilerinizi memnun etmek, müşteri sadakati
                        oluşturmak ve daha fazla gelir elde etmek için
                        önemlidir. Bu nedenle, IThink olarak, müşteri deneyimini
                        en önemli faktör olarak görüyoruz. Sunduğumuz QR menü
                        çözümü, müşterilerinizin işletmenizde daha iyi bir
                        deneyim yaşamasını sağlar. Ayrıca, işletmenizin marka
                        değerini artırmak için tasarımı da önemli bir faktördür.
                        İşletmenizin temsil ettiği değerleri, estetik ve modern
                        tasarımımızla yansıtıyoruz. Böylece müşterileriniz,
                        işletmenizin daha modern ve teknolojik bir görünüme
                        sahip olduğunu görebilir. IThink olarak, işletmenizde
                        daha iyi bir müşteri deneyimi yaratmak için sürekli
                        olarak gelişiyor ve yenilikler yapıyoruz. Sunduğumuz QR
                        menü çözümü, müşterilerinizin işletmenize daha sık
                        gelmesini sağlayacak ve işletmenizin başarısını
                        artıracaktır. Sonuç olarak, IThink olarak, işletmenizin
                        müşteri deneyimini öncelikli kılan bir felsefeye
                        sahibiz. Sunduğumuz QR menü çözümü, müşterilerinizi
                        memnun etmek ve daha fazla gelir elde etmek için
                        tasarlanmıştır. İşletmenizin daha modern bir görünüme
                        sahip olmasını ve marka değerini artırmasını sağlamak
                        için tasarımımızın önemli olduğuna inanıyoruz.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <h3>IThink QR menü çözümü nedir?</h3>
                      <p>
                        IThink QR menü çözümü, müşterilerin mobil cihazlarından
                        sipariş vermesine olanak tanıyan bir çözümdür.
                        İşletmeler, müşterilerin QR kodu taramasıyla menüyü
                        görüntülemelerine ve sipariş vermelerine izin verir.
                      </p>
                      <h3>QR menü nasıl çalışır?</h3>
                      <p>
                        IThink QR menü çözümü, işletmelerin menülerini QR kodu
                        olarak sunmasına izin verir. Müşteriler, mobil
                        cihazlarından QR kodu taraması yaparak menüyü
                        görüntüleyebilirler. Daha sonra, siparişlerini mobil
                        cihazlarından vererek garson çağırmaya gerek kalmadan
                        siparişlerini verebilirler.{" "}
                      </p>
                      <h3>QR menü çözümü hangi işletmelere uygun? </h3>
                      <p>
                        IThink QR menü çözümü, restoranlar, kafeler, barlar,
                        oteller gibi birçok işletmeye uygundur.
                      </p>
                      <h3>QR menü kullanmak güvenli mi?</h3>
                      <p>
                        IThink QR menü çözümü, müşterilerin kişisel bilgilerini
                        korumak için gereken tüm önlemleri almaktadır. Ayrıca,
                        müşterilerin hijyen kurallarına uymalarını sağlamak için
                        fiziksel menülere kıyasla daha hijyeniktir.
                      </p>
                      <h3>IThink QR menü çözümü nasıl kurulur?</h3>
                      <p>
                        IThink QR menü çözümü, işletmenizin ihtiyaçlarına uygun
                        olarak özelleştirilebilir ve kolayca kurulabilir. IThink
                        ekibi, işletmenize özel bir QR menü çözümü oluşturmak
                        için size yardımcı olabilir.
                      </p>
                      <h3>IThink QR menü çözümü ne kadar maliyetlidir?</h3>
                      <p>
                        IThink QR menü çözümü, işletmenizin ihtiyaçlarına ve
                        taleplerine bağlı olarak değişebilir. IThink ekibi, size
                        en uygun maliyetli QR menü çözümünü sunmak için
                        çalışacaktır.
                      </p>
                      <h3>
                        IThink QR menü çözümü işletmelere nasıl yardımcı olur?
                      </h3>
                      <p>
                        IThink QR menü çözümü, işletmelerin işletme
                        maliyetlerini azaltarak ekonomik olarak katkı sağlar.
                        Ayrıca, işletmelerde fazladan garson çalışan sayısına
                        ihtiyaç duyulmaz. İşletmelerde yoğunluk olduğu zaman
                        müşterilerin garson çağırmak için sıra beklemesine gerek
                        kalmadan siparişlerini hızlı ve kolay bir şekilde
                        verebilmelerini sağlar. Bu da işletmelerin müşteri
                        memnuniyetini artırır ve işletmenin verimliliğini
                        artırarak ekonomik olarak katkı sağlar.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
