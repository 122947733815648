import React from "react";
import { Link } from "react-router-dom";
import "../common/css/homepage.css";

const Introduction2 = () => {
  return (
    <>
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Müşterileriniz İçin Sipariş Girin
                  </h2>
                  <p>
                    IThink müşterilerinizin siparişleri mobil ekrandan
                    vermedikleri durumlar için işletmeci olarak sizin
                    siparişleri girebileceğiniz bir ekrana sahiptir. Bu ekrandan
                    her kategorideki ürünlere ulaşıp seçtiğiniz masa için
                    sipariş girebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/siparis.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction2;
