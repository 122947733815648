const menu_data = [
  {
    id: 1,

    title: "Ana Sayfa",
    link: "/",
  },
  {
    id: 2,

    title: "Hakkımızda",
    link: "/about",
  },
  {
    id: 3,
    hasDropdown: true,
    title: "Uygulamalarımız",
    link: "/app-details-qr",
    submenus: [
      { title: "QR Menü", link: "/app-details-qr" },
      { title: "Admin Panel", link: "/app-details-panel" },
    ],
  },
  {
    id: 4,

    title: "Fiyatlandırma",
    link: "/price",
  },
  {
    id: 5,

    title: "Bize Ulaşın",
    link: "/contact",
  },
];

export default menu_data;
