import React from "react";

import "../common/css/homepage.css";

const Introduction1 = () => {
  return (
    <>
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/masalar.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Müşterilerinizin İsteklerini Öğrenin
                  </h2>
                  <p>
                    Ithink kullanarak masalarınızı kolayca kontrol
                    edebilirsiniz. Hangi masaların dolu olduğunu, hangi
                    masaların boş olduğunu anlık olarak görebilirsiniz. Ayrıca,
                    müşterileriniz için siparişler girebilir ve hesap
                    işlemlerini yapabilirsiniz.
                  </p>
                  <p className="pt-10">
                    Aynı zamanda, sağ taraftaki bildirim paneli sayesinde
                    müşterilerinizin isteklerini anlık olarak takip
                    edebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction1;
