import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_db483cu",
        "template_fwrv61i",
        form.current,
        "iKIA_7IHPeLH0WmJJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Mesaj başarıyla gönderildi!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log("message send");
        },
        (error) => {
          console.log(error.text);
          toast.error("Mesaj gönderilemedi!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log("message cant send");
        }
      );
  };
  return (
    <>
      <div className="tp-sv-contact grey-bg-4 pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center pb-40">
                <h2 className="tp-section-title">
                  Aradığınız şey <br />
                  burada yok mu?
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-lg-8">
              <div className="tp-contact-form tp-contact-form-2 white-bg pt-50 pl-50 pr-50 pb-55 mr-60">
                <h4 className="tp-contact-form-title">
                  Bizimle İletişime Geçin
                </h4>
                <form ref={form} onSubmit={sendEmail}>
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Ad ve Soyad*"
                    required
                  />
                  <br />
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Mail Adresi*"
                    required
                  />
                  <br />
                  <input
                    type="text"
                    name="user_phone"
                    placeholder="Telefon Numarası*"
                    required
                  />
                  <br />
                  <input type="text" name="user_address" placeholder="Adres" />
                  <br />
                  <textarea
                    name="message"
                    placeholder="İleti*"
                    required
                  ></textarea>
                  <button type="submit" value="Send" className="tp-btn-border">
                    Gönder
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tp-ct-info-box black-bg h-price">
                <div className="tp-ct-info tp-ct-info-border pt-50 pl-50 pb-35">
                  <h3 className="tp-ct-info__title text-white mb-35">
                    <span>
                      <i className="fal fa-address-book"></i>
                    </span>{" "}
                    Adresimiz
                  </h3>
                  <p>
                    Kayaşehir Mahallesi , Kayaşehir Bulvarı <br></br>
                    Başakşehir/İstanbul <br></br>
                  </p>
                </div>
                <div className="tp-ct-info pt-60 pl-50 pb-35">
                  <h3 className="tp-ct-info__title text-white mb-35">
                    <span>
                      <i className="fal fa-address-book"></i>
                    </span>{" "}
                    İletişim Bilgilerimiz
                  </h3>
                  <p>
                    (531) 331-9481 <br></br>
                    (538) 989-4163
                  </p>
                </div>
                <div className="tp-ct-info pt-60  pb-50 black-bg-2">
                  <div className="tp-ct-info-icons centered">
                    <span>
                      <a href="https://www.linkedin.com/in/ithink-menu-49a983282/">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </span>

                    <span>
                      <a href="https://www.instagram.com/ithinkmenu/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  );
};

export default Contact;
