import React from "react";
import { Link } from "react-router-dom";
import "../common/css/homepage.css";

const Feature = () => {
  return (
    <>
      {/* <!-- feature area start  --> */}
      <div className="tp-feature-area position-relative space">
        <div
          className="tp-fe-bg d-none d-lg-block"
          style={{
            backgroundImage: `url(/assets/img/bg/bg-1.jpg)`,
          }}
        ></div>
        <div className="container-fluid p-0">
          <div className="row g-0 justify-content-end">
            <div className="col-xl-4 col-lg-12 tp-modify-width d-block">
              <div className="tp-section-title-sm-box tp-white-text grey-bg-3 hide-content">
                <span className="tp-section-subtitle-sm">
                  Gelİşmeye ne dersİnİz
                </span>
                <h3 className="tp-section-title-sm tp-white-text-sm mb-5">
                  İşletmeniz İçin Benzersiz ve Modern Çözümler
                </h3>
                <p>
                  IThink QR menü çözümü, müşterilerin mobil cihazlarından
                  sipariş vermesine olanak tanıyan bir çözümdür. İşletmeler,
                  müşterilerin QR kodu taramasıyla menüyü görüntülemelerine ve
                  sipariş vermelerine izin verir.
                </p>
              </div>
              <div className="tp-section-title-sm-box tp-white-text black-bg hide-content">
                <span className="tp-section-subtitle-sm">
                  Ne Kazandırıyoruz?
                </span>
                <h3 className="tp-section-title-sm tp-white-text-sm mb-5">
                  Uygulamamızın Avantajları
                </h3>
                <p>
                  IThink mobil sipariş özelliğiyle , ortalama sipariş miktarını
                  %20 artırır, masa başına 20 dakika kazandırır ve %40 daha
                  fazla bahşiş almanızı sağlar. Müşteriler kendi siparişlerini
                  spesifik bir şekilde verebileceği için garsona olan ihtiyaç
                  azalır. Hata payı minimuma iner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- feature area end   --> */}
    </>
  );
};

export default Feature;
