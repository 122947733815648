import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";

import AdminPanelPage from "./AdminPanelPage";

const AppDetailsPanel = () => {
  return (
    <>
      <Menu header_style_2={true} />

      <AdminPanelPage />
      <FooterThree />
    </>
  );
};

export default AppDetailsPanel;
