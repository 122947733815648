import React from "react";
import { Link } from "react-router-dom";
import "../common/css/homepage.css";

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140 pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <span className="tp-section-subtitle">
                    İŞ YERİNİZİ GELİŞTİRİYORUZ
                  </span>
                  <h2 className="tp-section-title pt-25 mb-30">
                    Restoran, Cafeler İçin IThink Sipariş Çözümleri
                  </h2>
                  <p>
                    IThink QR Menü mobil sipariş çözümleriyle misafir
                    deneyiminizi geliştirin. Markanıza özel oluşturacağınız
                    dijital menüler ile müşterileriniz ürünlerinizi
                    görüntüleyebilir, sipariş verebilir ve ödemelerini
                    istedikleri biçimde talep edebilir. Böylece hatalar
                    engelenir ve süreç hızlanır. Personelinizin misafirperverlik
                    için daha fazla zamanı kalır...
                  </p>
                  <p className="pt-10">
                    Ayrıca IThink Restoran paneli size işletmenizle ilgili
                    istatistikler sunar. Dolayısıyla işinizi büyütmek için daha
                    fazla argüman geliştirebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img src="assets/img/about/about.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-45 mb-30 centered">
            <div className="tp-theme-btn d-flex align-items-center">
              <Link to="/contact">
                <div className="tp-btn-inner">
                  <p className="mb-0">HADİ BAŞLAYALIM</p>
                  <b>Bize Ulaşın</b>
                  <span className="d-inline-block">
                    <svg
                      width="46"
                      height="8"
                      viewBox="0 0 46 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      width="46"
                      height="8"
                      viewBox="0 0 46 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
