import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";

import BossPanelPage from "./BossPanelPage";

const AppDetailsBoss = () => {
  return (
    <>
      <Menu header_style_2={true} />

      <BossPanelPage />
      <FooterThree />
    </>
  );
};

export default AppDetailsBoss;
