import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import HomeSlider from "../../Home/HomeSlider";

import QrMenuPage from "./QrMenuPage";

const AppDetailsQr = () => {
  return (
    <>
      <Menu header_style_2={true} />

      <QrMenuPage />
      <FooterThree />
    </>
  );
};

export default AppDetailsQr;
