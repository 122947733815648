import React from "react";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const FooterThree = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-50 ">
                <div className="row">
                  <div className="col-3 col-md-3 footer-logo">
                    <Link to="/" href="/">
                      <img
                        src="/assets/img/logo/logowhite.png"
                        width={200}
                        height={200}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Medya Hesaplarımız
                      </h3>
                      <ul className="">
                        <li>
                          <a href="#" className="icon-footer">
                            <LinkedInIcon /> Linkedin
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <InstagramIcon /> İnstagram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Adresİmİz
                      </h3>
                      <p>
                        {" "}
                        Kayaşehir Mahallesi , Kayaşehir Bulvarı
                        Başakşehir/İstanbul<br></br>
                        +90 (531) 331-9481 <br></br>+90 (538) 989-4163
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 pb-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-copyrigh-text text-center text-white">
                <span>
                  © {new Date().getFullYear()} IThink Yazılım - Tüm Hakları
                  Saklıdır.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterThree;
