import React from "react";
import { Link } from "react-router-dom";
import "../common/css/homepage.css";

const Introduction4 = () => {
  return (
    <>
      <div className="tp-about-area  ">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-25 mb-30">
                    Menünüzü ve ürünlerinizi düzenleyin
                  </h2>
                  <p>
                    IThink müşterilerinize sunduğunuz menüyü düzenlemenize ve
                    daha iyi tanıtılmanızı sağlıyor.
                  </p>
                  <p className="pt-10">
                    Bu ekrandan ürünlerinizi düzenleyebilir, silebilir ya da
                    onlara yeni resimler ekleyebilirsiniz. Ürünlerinizin stokta
                    olmadığı durumlarda müşterilerinizin ilgili ürünü sipariş
                    vermesini engelleyebilirsiniz. Ürünlerinizi favori olarak
                    seçip bu ürünlerin menünüzde ön plana çıkmasını
                    sağlayabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img width={"80%"} src="assets/img/intro/menu.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction4;
