import React from "react";

import Menu from "../common/Menu/Menu";

import Feature from "./Feature";

import TopAbout from "./TopAbout";
import Introduction1 from "./Introduction1";
import Introduction2 from "./Introduction2";
import Introduction3 from "./Introduction3";
import Introduction4 from "./Introduction4";
import HomeSlider from "./HomeSlider";
import FooterThree from "../common/Footer/FooterThree";
import Testimonial from "../common/Testimonial/Testimonial";

const Home = () => {
  return (
    <>
      <Menu />
      <HomeSlider />
      <TopAbout />

      <Feature />
      <Introduction1 />
      <Introduction2 />
      <Introduction3 />
      <Introduction4 />
      <Testimonial />
      <FooterThree />
    </>
  );
};

export default Home;
