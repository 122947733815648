import React from "react";

const QrMenuPage = () => {
  return (
    <>
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-5">
              <div className="tp-about-right p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/anasayfamobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">Ana Sayfa</h2>
                  <p>
                    Müşterilerinizin masalardaki QR kodu okuttuktan sonra
                    karşılarına çıkan ekrandır.Müşterileriniz bu ekrandan yapmak
                    istedikleri bütün işlemleri gerçekleştirebilirler. Menünüzü
                    görüntüleyip siparişler verebilirler.
                  </p>
                  <p className="pt-10">
                    Ayrıca dil desteği sayesinde yabancı uyruklu müşterileriniz
                    konusunda sıkıntı çekmezsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-7">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">Menü Ekranı</h2>
                  <p>
                    Müşterilerin sipariş verebilecekleri ekrandır. Burada
                    işletmenizin favori ürünleri ve kategorileriniz
                    görüntülenir. Bu kategorilerin içinde de eklediğiniz ürünler
                    yer alır.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/menumobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-5">
              <div className="tp-about-right p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/urunmobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">Ürün Ekranı</h2>
                  <p>
                    Müşterileriniz sipariş etmek istedikleri ürünü seçtikten
                    sonra karşılarına çıkan ekrandır. Buradan ürünün detaylarını
                    inceleyebilirler ve içindeki malzemeleri
                    görüntüleyebilirler. İstemedikleri malzemeleri üründen
                    çıkartabilirler.
                  </p>
                  <p className="pt-10">
                    Ayrıca eklemek istedikleri bir şey varsa not düşebilirler.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-7">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Değerlendirme Ekranı
                  </h2>
                  <p>
                    Müşterileriniz işletmeniz hakkındaki yorumlarını bu ekran
                    sayesinde size iletebilir ve sağladığınız hizmeti
                    değerlendirebilir.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/degermobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}

      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-5">
              <div className="tp-about-right p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/garsonmobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Garson Talep Ekranı
                  </h2>
                  <p>
                    Müşterileriniz garsonlara gerek duyduğu durumlarda buradan
                    ricasını iletebilir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-7">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">Hesap Ekranı</h2>
                  <p>
                    Müşterileriniz bu ekrandan hesaplarını görüntüleyebilir. Ve
                    başarılı bir şekilde hesap talebi oluşturabilirler.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/hesapmobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-5">
              <div className="tp-about-right p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/sarkimobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Şarkı Talep Ekranı
                  </h2>
                  <p>
                    Müşterilerinizin işletmenizde çalan şarkıyı belirlemesi için
                    harika bir ekran!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-7">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Sipariş Durum Ekranı
                  </h2>
                  <p>
                    Müşterileriniz verdikleri siparişin ne durumda olduğunu
                    buradan görebilirler.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/siparisdurummobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-5">
              <div className="tp-about-right p-relative centered">
                <div className="about-img ">
                  <img
                    width={"50%"}
                    src="assets/img/intro/siparislistmobile.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Siparişler Ekranı
                  </h2>
                  <p>
                    Müşteriler girdikleri siparişleri buradan görüntüleyebilir.
                    Ve siparişlerini onaylayabilirler.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrMenuPage;
