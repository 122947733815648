import React from "react";
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from "../components/BackToTop";

import ContextProvider from "../components/context/ContextProvider";
import Home from "../components/Home/Home";

import About from "../components/Pages/About/About";

import ContactUs from "../components/Pages/ContactUs/ContactUs";
import FAQ from "../components/Pages/FAQ/FAQ";
import AppDetailsPanel from "../components/Pages/AppDetailsPanel/AppDetailsPanel";
import AppDetailsBoss from "../components/Pages/AppDetailsBoss/AppDetailsBoss";
import AppDetailsQr from "../components/Pages/AppDetailsQr/AppDetailsQr";
import Price from "../components/Pages/Price/Price";

const AppNavigation = () => {
  return (
    <ContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/about" element={<About />} />

        <Route path="/price" element={<Price />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/app-details-boss" element={<AppDetailsBoss />} />
        <Route path="/app-details-qr" element={<AppDetailsQr />} />
        <Route path="/app-details-panel" element={<AppDetailsPanel />} />
      </Routes>
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;
