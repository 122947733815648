import React from "react";

const AdminPanelPage = () => {
  return (
    <>
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/masalar.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right ">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Müşterilerinizin İsteklerini Öğrenin
                  </h2>
                  <p>
                    Ithink kullanarak masalarınızı kolayca kontrol
                    edebilirsiniz. Hangi masaların dolu olduğunu, hangi
                    masaların boş olduğunu anlık olarak görebilirsiniz. Ayrıca,
                    müşterileriniz için siparişler girebilir ve hesap
                    işlemlerini yapabilirsiniz.
                  </p>
                  <p className="pt-10">
                    Aynı zamanda, sağ taraftaki bildirim paneli sayesinde
                    müşterilerinizin isteklerini anlık olarak takip
                    edebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* *************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Müşterileriniz İçin Sipariş Girin
                  </h2>
                  <p>
                    IThink müşterilerinizin siparişleri mobil ekrandan
                    vermedikleri durumlar için işletmeci olarak sizin
                    siparişleri girebileceğiniz bir ekrana sahiptir. Bu ekrandan
                    her kategorideki ürünlere ulaşıp seçtiğiniz masa için
                    sipariş girebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/siparis.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/istatistik.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    İş Yerinizde Neler Oluyor Öğrenin
                  </h2>
                  <p>
                    IThink kazancınızın takibine ve daha da artmasına olanak
                    sağlıyor. İstatistik ekranı sayesinde iş yerinizdeki tüm
                    sayısal veriler elinizin altında!
                  </p>
                  <p className="pt-10">
                    Bu ekrandan haftalık ve günlük kazancınızdan bahşiş
                    miktarlarına kadar her şeyin grafiklerine ve verilerine göz
                    atabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-25 mb-30">
                    Menünüzü ve ürünlerinizi düzenleyin
                  </h2>
                  <p>
                    IThink müşterilerinize sunduğunuz menüyü düzenlemenize ve
                    daha iyi tanıtılmanızı sağlıyor.
                  </p>
                  <p className="pt-10">
                    Bu ekrandan ürünlerinizi düzenleyebilir, silebilir ya da
                    onlara yeni resimler ekleyebilirsiniz. Ürünlerinizin stokta
                    olmadığı durumlarda müşterilerinizin ilgili ürünü sipariş
                    vermesini engelleyebilirsiniz. Ürünlerinizi favori olarak
                    seçip bu ürünlerin menünüzde ön plana çıkmasını
                    sağlayabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img width={"80%"} src="assets/img/intro/menu.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/kategori.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Kategori Ekleyin
                  </h2>
                  <p>
                    Müşterilerinize sunacağınız ürünler için kategoriler
                    ekleyin. Mevcut olanları görüntüleyin, düzenleyin ya da
                    silin.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">Ürün Ekleyin</h2>
                  <p>
                    Belirlediğiniz kategorilerin içine ürünler ekleyin.
                    Ürünleriniz hakkında yeni ayrıntılar ve detaylı fotoğraflar
                    ekleyebilirsiniz.
                  </p>
                  <p className="pt-10">
                    Müşterileriniz bu şekilde sipariş verirken daha iyi fikirler
                    edinip istemedikleri malzemeleri siparişlerden
                    çıkartabilirler.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/urunler.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img ">
                  <img
                    width={"80%"}
                    src="assets/img/intro/hesaplar.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Hesap Geçmişinize Göz Atın
                  </h2>
                  <p>
                    IThink kapanan hesapları ve bu hesapların tüm detaylarını
                    görüntülemenize olanak sağlıyor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************************************** */}
      <div className="tp-about-area  pad-bot">
        <div className="container">
          <div className="row justify-content-end">
            {" "}
            <div className="col-lg-6">
              <div className="tp-about-right mt-4">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-50 mb-30">
                    Masalarınızı Düzenleyin
                  </h2>
                  <p>
                    İşletmenize yeni masalar ekleyin. Mevcut olanları
                    görüntüleyin ve adını değiştirin.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className=" p-relative centered">
                <div className="about-img ">
                  <img width={"80%"} src="assets/img/intro/ekleme.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPanelPage;
