import React from "react";
import Brands from "../../common/Brands/Brands";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";

import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
import AboutFeature from "./AboutFeature";

const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutCompany />

      <FooterThree />
    </>
  );
};

export default About;
